@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

.mantine-Container-root {
  max-width: 60%;
  margin-left: 10%;
  margin-right: 10%;
}

@media screen and (min-width: 1728px) {
  .mantine-Container-root {
    margin-left: 17%;
    margin-right: 10%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mantine-185qulc {
  border-top: none;
}

.mantine-v4lv9f {
  border-bottom: none;
}

* {
  font-family: 'Manrope', sans-serif !important;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background: #ababab;
  border-radius: 5px;
}
