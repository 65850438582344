.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .light {
  animation: typingAnimationLight 1s infinite ease-in-out;
  background-color: white;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dark {
  animation: typingAnimationDark 1s infinite ease-in-out;
  background-color: #5c4d8a;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dark:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dark:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dark:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dark:last-child {
  margin-right: 0;
}

.typing .light:nth-child(1) {
  animation-delay: 200ms;
}
.typing .light:nth-child(2) {
  animation-delay: 300ms;
}
.typing .light:nth-child(3) {
  animation-delay: 400ms;
}
.typing .light:last-child {
  margin-right: 0;
}

@keyframes typingAnimationDark {
  0% {
    transform: translateY(0px);
    background-color: #5c4d8a;
  }
  28% {
    transform: translateY(-7px);
    background-color: #5c4d8a;
  }
  44% {
    transform: translateY(0px);
    background-color: #5c4d8a;
  }
}

@keyframes typingAnimationLight {
  0% {
    transform: translateY(0px);
    background-color: white;
  }
  28% {
    transform: translateY(-7px);
    background-color: white;
  }
  44% {
    transform: translateY(0px);
    background-color: white;
  }
}
